import React, { useContext } from 'react';

import { PaymentPlans } from '../../../../constants/tour';
import { AppContext } from '../../../../apollo/wrap-root-element';
import { currencySymbolMap } from '../../../Header/currencyswitcher/CurrencySwitcher';

const TotalPayment = ({ travellerQuantity, finalAmount }) => {
  const { currency, currenciesRate } = useContext(AppContext);

  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);
  return (
    <div className="text-final-payment">
      <div className="label">Total Payment for {travellerQuantity} travellers</div>
      <div style={{ fontWeight: '700', fontSize: '1.4375rem' }}>
        {currencySymbolMap[currency] || '$'}
        {parseFloat((selectedRate.rate * finalAmount).toFixed(2))}
      </div>
    </div>
  );
};

const PartialPayment = ({ travellerQuantity, finalAmount }) => {
  const dueTodayAmount = finalAmount * 0.2; // 20% of final amount
  const remainingAmount = finalAmount - dueTodayAmount;
  const { currency, currenciesRate } = useContext(AppContext);

  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <>
      <div className="text-final-payment">
        <div className="label">Remaining payment for {travellerQuantity} travellers</div>
        <div style={{ fontWeight: '500' }}>
          {currencySymbolMap[currency] || '$'}
          {parseFloat((selectedRate.rate * remainingAmount).toFixed(2))}
        </div>
      </div>

      <div className="text-final-payment">
        <div className="label">Due Today</div>
        <div style={{ fontWeight: '700', fontSize: '1.4375rem' }}>
          {currencySymbolMap[currency] || '$'}
          {parseFloat((selectedRate.rate * dueTodayAmount).toFixed(2))}
        </div>
      </div>
    </>
  );
};

const FinalPayableAmount = ({
  totalTourAmount,
  preExtension,
  postExtension,
  paymentPlan = PaymentPlans.FULL,
  travellerQuantity,
}) => {
  const showAddOnCharge = preExtension.pricePerPerson || postExtension.pricePerPerson;
  const totalAddonCharge = preExtension.totalAmount + postExtension.totalAmount;
  const addOnCharge = paymentPlan === PaymentPlans.FULL ? totalAddonCharge : totalAddonCharge * 0.2;
  const subtotalCharge = paymentPlan === PaymentPlans.FULL ? totalTourAmount : totalTourAmount * 0.2;
  const finalAmount = totalTourAmount + totalAddonCharge;

  const { currency, currenciesRate } = useContext(AppContext);

  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <section
      style={{
        padding: '32px 0 0 0',
        borderTop: '1px solid #A1A1A1',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
      }}
    >
      <div className="text-final-payment">
        <div className="label">Subtotal payment for {travellerQuantity} travellers</div>
        <div style={{ fontWeight: '500' }}>
          {currencySymbolMap[currency] || '$'}
          {parseFloat((selectedRate.rate * subtotalCharge).toFixed(2))}
        </div>
      </div>

      {showAddOnCharge && (
        <div className="text-final-payment">
          <div className="label">Add on charge for {travellerQuantity} travellers</div>
          <div style={{ fontWeight: '500' }}>
            {currencySymbolMap[currency] || '$'}
            {parseFloat((selectedRate.rate * addOnCharge).toFixed(2))}
          </div>
        </div>
      )}

      {paymentPlan === PaymentPlans.FULL ? (
        <TotalPayment travellerQuantity={travellerQuantity} finalAmount={finalAmount} />
      ) : (
        <PartialPayment travellerQuantity={travellerQuantity} finalAmount={finalAmount} />
      )}
    </section>
  );
};

export default FinalPayableAmount;

import React from 'react';
import { Link } from 'gatsby';

import IconFail from '../../../images/icon-fail-cross.png';

const ErrorFallback = () => {
  return (
    <div
      className="modal-tour-error"
      style={{
        margin: '0 auto',
      }}
    >
      <div className="bg-light">
        <img src={IconFail} alt="Icon Success" role="presentation" />
        <h1>Oops! Something went wrong.</h1>

        <p>
          Sorry, we can't process your booking now. Please check input and try later. Contact support if needed. We
          apologize for any inconvenience.
        </p>

        <div
          style={{
            maxWidth: '345px',
            margin: '32px auto 0 auto',
          }}
        >
          <Link to="/contact" className="btn btn-md btn-primary-ii btn-submit w-full">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;

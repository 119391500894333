export const PaymentPlans = {
  FULL: 'full',
  PARTIAL: 'partial',
};

export const OccupancyTypes = {
  SINGLE: 'single',
  DOUBLE: 'double',
};

export const tourBookingDeadlineInDays = 8 * 7; // 8 weeks before tour date. Note that this is soft deadline during which the payments booked prior are expected to be settled
export const partialPaymentDisableDaysBefore = 7 + tourBookingDeadlineInDays; // 7 days before tour booking deadline

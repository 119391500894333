import React from 'react';

import { PaymentPlans } from '../../../constants/tour';

const PaymentPlan = ({ paymentPlan, setPaymentPlan, isPartialPaymentDisabled }) => {
  const handlePaymentChange = (event) => {
    setPaymentPlan(event.target.value);
  };

  return (
    <div
      style={{
        margin: '2rem 0',
      }}
    >
      <h3 className="radio-heading">Payment option</h3>

      <div className="radio-group">
        <input
          type="radio"
          id="partial-payment"
          name="payment-plan"
          value="partial"
          onChange={handlePaymentChange}
          checked={paymentPlan === PaymentPlans.PARTIAL}
          disabled={isPartialPaymentDisabled}
        />
        <label htmlFor="partial-payment" className="payment-plan-label">
          Pay 20% In Advance
        </label>
      </div>

      {isPartialPaymentDisabled && (
        <div style={{ marginTop: '0.5rem', maxWidth: '480px' }} className="text-sub">
          * Partial Payment option is currently unavailable
        </div>
      )}

      <div
        className="radio-group"
        style={{
          marginTop: '1rem',
        }}
      >
        <input
          type="radio"
          id="full-payment"
          name="payment-plan"
          value="full"
          onChange={handlePaymentChange}
          checked={paymentPlan === PaymentPlans.FULL}
        />
        <label htmlFor="full-payment" className="payment-plan-label">
          Pay Full
        </label>
      </div>

      <div style={{ marginTop: '0.5rem', maxWidth: '480px' }} className="text-sub">
        * 20% is your non refundable amount but if the tour is canceled by the admin, you will receive a full refund,
        including the 20% non-refundable amount.
      </div>
    </div>
  );
};

export default PaymentPlan;

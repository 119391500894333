import React from 'react';

const TourInfo = ({ tour }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '0.75rem',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: '4rem',
          width: '4rem',
          flexShrink: 0,
        }}
      >
        {tour?.thumbnail?.url && (
          <img
            src={tour?.thumbnail?.url}
            alt={tour?.featuredImage?.alt || 'Tour Thumbnail'}
            style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: '4px' }}
          />
        )}
      </div>
      <div>
        <h2 style={{ fontSize: '1.4375rem', lineHeight: '140%' }}>{tour.title}</h2>
      </div>
    </div>
  );
};

export default TourInfo;

import qs from 'qs';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import BodyClassName from 'react-body-classname';
import React, { useState, useEffect } from 'react';

import TourPayments from '../../components/Tour/TourPayments';
import BookingError from '../../components/Tour/TourPayments/ErrorFallback';
import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';

const TourCancellation = () => {
  const location = useLocation();

  const queryString = qs.parse(location.search.substring(1));
  const { token } = queryString;
  const { bookingId } = queryString;

  const [tourBooking, setTourBooking] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState();

  const onBookingFetchSuccess = (data) => {
    setTourBooking(data);
  };

  const onBookingFetchFail = () => {
    setError(true);
  };

  useEffect(() => {
    if (!token || !bookingId) {
      navigate('/tours');
    }

    setIsFetching(true);

    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/tour-bookings/${bookingId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((body) => {
        if (body.success) {
          onBookingFetchSuccess(body.data);
        } else {
          onBookingFetchFail();
        }
      })
      .catch((err) => {
        console.error(err.message);
        onBookingFetchFail();
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [location]);

  return (
    <BodyClassName className="body-dark">
      <DefaultLayoutComponent>
        <div className="site-main">
          <div className="wrapper wrapper-md pd-x-md">
            <div className="bg-light tourBooking">
              {error ? (
                <BookingError />
              ) : (
                <>
                  <h1>Package Details</h1>
                  <div className="tour-booking-body">
                    {isFetching && <span className="spinner"></span>}
                    {tourBooking && <TourPayments token={token} tourBooking={tourBooking} />}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default TourCancellation;

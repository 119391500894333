import { Box } from '@mui/material';
import { differenceInDays } from 'date-fns';
import React, { useState, useMemo } from 'react';

import Grid from '../../Grid';
import PaymentPlan from './PaymentPlan';
import { fromCents } from '../../../utils/fromCents';
import { PaymentPlans } from '../../../constants/tour';
import TourErrorModal from '../TourBooking/TourErrorModal';
import TourInfo from '../TourBooking/PackageDetails/TourInfo';
import FinalPayableAmount from '../TourBooking/PackageDetails/FinalPayableAmount';
import { partialPaymentDisableDaysBefore } from '../../../constants/tour';

const PackageDetails = ({ tourBooking, token }) => {
  const tour = tourBooking.tourId;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [paymentPlan, setPaymentPlan] = useState(PaymentPlans.FULL);

  const onFormSubmitSuccess = ({ url }) => {
    if (url && window?.location) {
      //  Using window.location over navigate() because navigate() strips the URL fragements
      window.location.href = url;
    }
  };

  const onFormSubmitFail = () => {
    setIsErrorModalOpen(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/tour-bookings/${tourBooking._id}/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        paymentPlan,
      }),
    })
      .then((resp) => resp.json())
      .then((body) => {
        if (body.success) {
          onFormSubmitSuccess(body.data);
        } else {
          onFormSubmitFail();
        }
      })
      .catch((err) => {
        console.error(err.message);
        onFormSubmitFail();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const pricePerPerson = useMemo(() => {
    if (tourBooking.occupancyType === 'single') {
      return {
        tour: fromCents(tour.pricePerPersonInCents.singleOccupancy),
        preExtension: fromCents(tour.pricePerPersonInCents.preExtensionSingleOccupancy),
        postExtension: fromCents(tour.pricePerPersonInCents.postExtensionSingleOccupancy),
      };
    }
    return {
      tour: fromCents(tour.pricePerPersonInCents.doubleOccupancy),
      preExtension: fromCents(tour.pricePerPersonInCents.preExtensionDoubleOccupancy),
      postExtension: fromCents(tour.pricePerPersonInCents.postExtensionDoubleOccupancy),
    };
  }, [tourBooking.occupancyType]);

  const travellerQuantity = tourBooking.additionalMembers.length + 1; // +1 for the main traveller
  const totalTourAmount = travellerQuantity * pricePerPerson.tour;

  const preExtension = {
    pricePerPerson: pricePerPerson.preExtension,
    isSelected: tourBooking.preExtension,
    totalAmount: tourBooking.preExtension ? travellerQuantity * pricePerPerson.preExtension : 0,
  };

  const postExtension = {
    pricePerPerson: pricePerPerson.postExtension,
    isSelected: tourBooking.postExtension,
    totalAmount: tourBooking.postExtension ? travellerQuantity * pricePerPerson.postExtension : 0,
  };

  const isPartialPaymentDisabled = useMemo(() => {
    return differenceInDays(new Date(tour.tourStartDate), new Date()) < partialPaymentDisableDaysBefore;
  }, [tour.tourStartDate]);

  return (
    <>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <form onSubmit={handleSubmit} className="package-details">
            <TourInfo
              tour={{
                title: tour.name,
                thumbnail: {
                  url: tour.image,
                },
              }}
            />

            <PaymentPlan
              paymentPlan={paymentPlan}
              setPaymentPlan={setPaymentPlan}
              isPartialPaymentDisabled={isPartialPaymentDisabled}
            />

            <FinalPayableAmount
              totalTourAmount={totalTourAmount}
              preExtension={preExtension}
              postExtension={postExtension}
              travellerQuantity={travellerQuantity}
              paymentPlan={paymentPlan}
            />

            <div className="btn-group">
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '17.625rem',
                  },
                }}
              >
                {isSubmitting ? (
                  <button
                    type="button"
                    className="btn-md btn-outline btn-loading w-full"
                    disabled
                    style={{
                      cursor: 'not-allowed',
                    }}
                  >
                    <span className="spinner"></span> Submitting
                  </button>
                ) : (
                  <button type="submit" className="btn btn-md btn-primary-ii btn-submit w-full">
                    Next
                  </button>
                )}
                <p
                  style={{
                    marginTop: '0.3125rem',
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    color: '#656565',
                  }}
                >
                  After clicking the "NEXT" button, you will be directed to the Stripe website.
                </p>
              </Box>
            </div>
          </form>
        </Grid.Col>
      </Grid>
      <TourErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </>
  );
};

export default PackageDetails;
